.banner .nav li {
  @extend .nav-item;

  &:last-child {
    a {
      padding-right: 0;

      @include hd {
        margin-right: 0;
      }
    }
  }
}

.banner .nav a {
  @extend .nav-link;

  letter-spacing: 1px;
  padding: 15.5px 19.1px 10.8px;

  @include wide {
    margin: 0 6.1px;
    letter-spacing: 1.6px;
    padding: 19.5px 23.1px 14.8px;
  }

  @include hd {
    padding: 23.5px 32.1px 18.8px;
  }

  &::after {
    @include hd {
      top: 22px;
      position: absolute;
    }
  }

  &[target="_blank"]::after {
    display: none;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

.banner {
  border-bottom: 7px solid $brand-primary;
  box-shadow: 0 3px white;
  z-index: 1;
  background: white;
  // position: absolute;
  width: 100%;
  height: fit-content;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 206px;

  @include tablet {
    width: 30%;
  }

  @include laptop {
    width: 23.2%;
  }

  @include desktop {
    width: 20.2%;
  }

  img {
    top: 1.5px;
    position: relative;

    @include hd {
      right: 15.5px;
    }
  }
}

.nav-right {
  width: 70%;

  @include laptop {
    width: 76.8%;
  }

  @include desktop {
    width: 79.8%;
  }
}

.nav {
  justify-content: flex-end;

  @include hd {
    margin-right: -1px;
  }
}

.nav-top {
  .nav {
    li {
      a {
        color: black;

        @include links-transition;

        &:hover {
          color: $brand-primary;
        }
      }
    }
  }
}

.nav-primary {
  background-image: url('https://imagedelivery.net/hYVz_KLysEMBk-HGZuEADA/4c2983c9-7c7c-4347-5472-aa20850cb400/1920px');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  @include links-transition;

  @include hd {
    margin-bottom: -1px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -14990px;
    width: 15000px;
    height: 100%;
    background-color: $brand-primary;
  }

  .nav {
    @include hd {
      margin-right: -3px;
    }

    li {
      a {
        color: $white;
        text-transform: uppercase;
        z-index: 1;
        position: relative;
        padding: 18.5px 10.1px 7px;

        @include links-transition;

        &:hover {
          color: #b9b900;
        }

        @include wide {
          padding: 18.5px 17.1px 7px;
        }

        @include hd {
          padding: 22.5px 32.1px 12px;
        }
      }
    }
  }
}

.hamburger {
  margin: 0 0 -8px;
}

.nav-primary .sub-menu,
.nav-top .sub-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #fbfbfb;
  padding: 14px 0;
  margin: 0;
  list-style: none;
  z-index: 2;
  box-shadow: rgb(0 95 186) 0px 0px 0 3px, rgb(0 95 186) 0px 1px 0 3px;
  min-width: 300px;
  display: block;

  @include links-transition;

  li {
    a {
      color: black;
      padding: 8px 40px;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}

.nav-top .sub-menu {
  z-index: 11;
}

.menu-item-has-children {
  > a {
    &::after {
      content: '\f0d7';
      margin-left: 6px;
      font-family: "Font Awesome 6 Pro", sans-serif;
      display: inline-block;
      font-weight: 600;
      top: -1px;
      position: relative;
    }
  }

  &.open {
    .sub-menu {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
}

.sideNav {
  .nav .sub-menu {
    position: relative;
    display: none;
    list-style: none;
    padding: 13px 10px;
    background: #f7f7f7;

    li {
      position: relative;
      
      a {
        position: relative;
        color: black;
        padding: 8.5px 19.1px;
      }
    }
  }

  .nav {
    .menu-item-has-children.open {
      .sub-menu {
        display: block;
      }
    }
  }
}
