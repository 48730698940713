a[href^="tel:"] {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

p a[target="_blank"]::after {
  content: '\f08e';
  margin-left: 8px;
  font-family: "Font Awesome 6 Pro", sans-serif;
}

#recaptcha_text a::after {
  display: none;
}

.search-form {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;

  @include mobile {
    display: block;
  }

  @include laptop {
    width: fit-content;
    padding: 0;
  }

  >label {
    width: 100%;
    margin-bottom: 0;

    @include mobile {
      width: 80%;
    }

    @include laptop {
      width: fit-content;
    }
  }

  .search-field {
    border: 1px solid $brand-primary;
  }

  .search-submit {
    padding: 13px;
    background: $brand-primary;
    color: $white;
    border: none;
    margin-left: 0;
    width: 100%;

    @include mobile {
      margin-left: -5px;
      width: 20%;
    }

    @include laptop {
      width: fit-content;
    }
  }
}

.btn-primary {
  border-radius: 0;
  border: 1px solid $white;
  text-transform: uppercase;
  padding: 12px 32.5px 10px 28px;
  display: inline-block;
  width: 100%;

  @include tablet {
    width: fit-content;
  }
}

// .page-header-margin,
// .thank-you-image {
//   margin-top: 105px;
// }

.single-page {
  .entry-title {
    display: none;
  }
}

.byline.author {
  display: none;
}

.page-header h1 {
  margin-bottom: 25px;
}

.thank-you {
  .social-icon {
    font-size: 35px;
    margin: 0 12px;

    a::after {
      display: none;
    }

    i {
      color: #3f556c;

      @include links-transition;

      &:hover {
        color: #7d7d7d;
      }
    }

    img {
      width: 50px;
      filter: opacity(0.5) drop-shadow(0 0 0 $brand-primary);

      @include links-transition;

      &:hover {
        filter: opacity(0.5) drop-shadow(0 0 0 white);
      }
    }
  }
}

.alignleft {
  display: block;
  float: none;

  @include laptop {
    float: left;
    margin-right: 2.5rem;
    display: inline;
  }
}

.alignright {
  display: block;
  float: none;
  

  @include laptop {
    float: right;
    margin-left: 2.5rem;
    display: inline;
  }
}

.tour-bg {
  padding: 10px 8px 28px 8px;
}

.tour-image {
  object-fit: cover;
  width: 100%;
  float: left;
  display: block;
  box-shadow: 0 1px 4px 0 rgba($black, 0.3);
  height: 100%;
  position: relative;
  min-width: 100%;
  width: 100%;

  @include laptop {
    min-width: 30%;
    width: 30%;
  }

  //   &::after,
  //   i {
  //   will-change: visibility, opacity;
  //   visibility: hidden;
  //   opacity: 0;
  
  //   @include links-transition(all, 0.3s, ease-in-out);
  // }
  
  // &::after {
  //   content: '';
  //   background-color: $brand-primary;
    
  //   @include stretch;
  // }
  
  // i {
  //   position: absolute;
  //   transform: translate(-50%, -50%);
  //   left: 50%;
  //   top: 50%;
  //   z-index: 300;
  //   color: $white;
  //   font-size: 1.5em;
  // }
  
  // &:hover {
  //   &::after {
  //     visibility: visible;
  //     opacity: 0.3;
  //   }
    
  //   i {
  //     visibility: visible;
  //     opacity: 1;
  //   }
  // }

  img {
    width: 100%;
  }
}

.tour-info {
  height: 100%;

  h1,
  p {
    color: $white;
  }

  h1 {
    margin-bottom: 15px;
  }

  .btn-primary {
    padding: 14px 32.5px 14px;
  }

  .btn-primary[target="_blank"]::after {
    display: none;
  }
}

.mailto::after {
  display: none;
}

.tour-image {
  @include laptop {
    margin-right: 27px;
  }
}

.tour-info-inner {
  text-align: center;

  @include laptop {
    // margin-left: 27px;
    text-align: left;
  }
}

article {
  border: 1px solid $brand-primary;
  padding: 20px 30px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.updated {
  display: none;
}

.staff-container {
  .card {
    box-shadow: 0 0 5px #0000002b;
    border: none;
    border-radius: 0;
    height: 100%;

    @include wide {
      height: fit-content;
    }
  }

  .staff-info {
    margin: -29px 20px 20px 20px;
    background: white;
    padding: 10px 20px;

    hr {
      width: 72px;
      margin-left: 0;
      border-top: 2px solid $brand-primary;
    }
  }

  .name {
    font-size: 20px;
    font-weight: 500;
  }
}

iframe {
  width: 100%;  
  height: 400px;
  border: none;
}

.contact-card {
  background: white;
  padding: 20px;
}

.attractions {
  .attractions-title:not(:first-child) {
    h1 {
      margin-top: 60px;
    }
  }

  .card {
    height: 100%;

    .card-image-link {
      &::after {
        display: none;
      }
    }

    .card-info {
      padding: 20px;
    }

    .image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid #dfdfdf;
      height: 266px;

      @include mobile {
        height: 300px;
      }

      &::after,
      i {
      will-change: visibility, opacity;
      visibility: hidden;
      opacity: 0;
    
      @include links-transition(all, 0.3s, ease-in-out);
    }
    
    &::after {
      content: '';
      background-color: #1e3f60a8;
      
      @include stretch;
    }
    
    i {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      z-index: 300;
      color: $white;
      font-size: 1.5em;
    }
    
    &:hover {
      &::after {
        visibility: visible;
        opacity: 1;
      }
      
      i {
        visibility: visible;
        opacity: 1;
      }
    }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
    }

    p {
      margin: 0;
    }

    hr {
      border-top: 2px solid #829401;
      margin-left: 0;
      width: 72px;
    }
  }
}

.accordion {
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  margin: 0 15px;
}
.accordion h2 {
  text-align: center;
}
.accordion .accordion-content {
  background-color: #e6e6e6;
  border-radius: 4px;
  margin: 0 0 24px;
  overflow: hidden;
}

.accordion-content.open {
  padding-bottom: 27px;
}

.accordion-content .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 19px 15px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.accordion-content.open header {
  min-height: 50px;
}
.accordion-content .description {
  height: 0;
  padding: 0 15px;
  transition: all 0.3s linear;
  margin: 0;
}

.accordion-content .title {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0;
}
.accordion-content .header i {
  font-size: 20px;
  cursor: pointer;
}

.moretext {
  display: none;
}

.scenic-flights-img {
  width: 100%;

  @include phablet {
    width: 50%;
  }
}

.gallery-iframes {
  @include phablet {
    width: 100%;
  }

  @include tablet {
    margin-left: -3px!important;
  }

  iframe {
    height: 268px;

    @include mobile {
      height: 400px;
    }
  }
}
