.sidebar {
  padding: 10px 20px;
  border: 1px solid $brand-primary;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 4px 0;
    }
  }
}