.why-choose {
  @include hd {
    padding: 60px 0 70px;
  }

  .why-choose-title {
    text-align: center;
    color: #434343;
    font-size: 55px;
    font-weight: 800;
    margin: 0 0 0 -4px;
    font-size: 43px;
    line-height: 1;

    @include phablet {
      line-height: 1.2;
    }

    @include tablet {
      font-size: 55px;
    }

    @include hd {
      margin: -11px 0 0 -4px;
    }
  }

  .why-choose-lead {
    text-align: center;
    margin: 12px 416px 0;
    line-height: 1.5;
    margin: 12px auto;

    @include tablet {
      width: 689px;
    }

    @include hd {
      margin: 12px 416px 0;
    }
  }

  .features {
    margin-top: 60px;
  }

  .feature-icon {
    background: $brand-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 74px;
    height: 74px;
    // outline: 3px solid white;
    border: 2px solid white;
    box-shadow: 0 0 0 3px $brand-primary;
    margin: 0 auto;

    @include desktop {
      margin: -2px 0 0 -2px;
    }

    @include wide {
      height: 103px;
      width: 103px;
      border: 3px solid white;
    }

    img {
      max-width: 35px;

      @include wide {
        max-width: 100%;
      }
    }
  }

  .feature-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 19.9px;
    margin: 7px 0 0 0;
    font-family: 'poppins', 'sans-serif';
  }

  hr {
    border-color: $brand-primary;
    border-width: 2px;
    margin: 12px 0 10px;
  }

  .feature-right-content {
    margin-bottom: 45px;

    @include hd {
      margin-left: -3px;
      width: 324px;
    }

    p {
      line-height: 1.5;
    }
  }

  .btn-primary {
    padding: 12px 28px 10px;
    margin-left: 3px;
    margin-top: -1px;
  }
}

.services {
  .service-image {
    background-size: cover;
    background-position: center;
    position: relative;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    left: 50%;
    height: 300px;

    @include phablet {
      height: 400px;
    }

    @include laptop {
      height: 100%;
      left: 103.4%;
      width: 50vw;
    }

    @include desktop {
      left: 102.8%;
    }

    @include hd {
      left: 102%;
    }
  }

  .service-content {
    padding: 21px 0 39px;

    @include mobile {
      padding: 32px 0 55px;
    }

    @include laptop {
      width: 80%;
    }

    @include desktop {
      padding: 38px 0 55px;
    }

    @include wide {
      padding: 72px 0 86px;
    }

    @include hd {
      width: 592px;
      margin-left: 123px;
    }

    h1 {
      font-weight: 800;
      line-height: 1;
      margin-bottom: 18px;
      font-size: 40px;

      @include phablet {
        margin-bottom: 15px;
      }

      @include wide {
        font-size: 55px;
      }

      span {
        font-size: 53px;

        @include wide {
          font-size: 70px;
        }
      }
    }

    h2 {
      font-size: 30.7px;
      margin-top: -16px;
    }

    p {
      line-height: 1.37;
      margin-top: 14px;
    }

    .btn-primary {
      margin-top: 7px;

      span {
        display: none;

        @include phablet {
          display: inline;
        }
      }
    }
  }

  .service .even {
    flex-direction: row-reverse;

    .service-image {
      @include laptop {
        left: -15px;
        right: 50%;
        margin: 0;
      }
    }

    .service-content {
      @include hd {
        margin-left: -74px;
      }
    }
  }
}
