.slick-dotted.slick-slider {
  margin-bottom: 3px;
}

.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 8px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 10px;
    }

    button::before {
      position: relative;
      opacity: 1;
      border: 8px solid #303131;
      border-radius: 100%;
      background-color: white;
      font-size: 8px;
      color: transparent;
    }

    .slick-active button::before {
      opacity: 1;
      border-color: $brand-primary;
      background-color: $brand-primary;
      color: $brand-primary;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;
    padding: 1px;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: #303131;
      opacity: 1;
    }

    &.slick-prev::before {
      content: '\f104';
    }

    &.slick-next::before {
      content: '\f105';
    }
  }
}
