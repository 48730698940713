.testimonials-panel {
  padding: 30px 0 56px;

  @include tablet {
    padding: 40px 0 46px;
  }

  @include laptop {
    padding: 46px 0 53px;
  }

  @include wide {
    padding: 46px 0 66px;
  }

  .testimonials-title {
    margin-bottom: 18px;

    @include tablet {
      margin-bottom: 41px;
    }

    h2 {
      font-weight: 800;
      margin-left: -4px;
      color: #444342;
      font-size: 43px;
      line-height: 1;

      @include mobile {
        line-height: 1.2;
      }

      @include tablet {
        font-size: 55px;
      }
    }

    p {
      margin: 0 auto;
      left: -4px;
      top: 5px;
      position: relative;
      line-height: 1.5;

      @include laptop {
        width: 650px;
      }
    }
  }

  .testimonial-row {
    justify-content: space-evenly;
    padding: 30px 0;
  }

  .quote-icon {
    background: $brand-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 74px;
    height: 74px;
    // outline: 3px solid white;
    border: 2px solid white;
    box-shadow: 0 0 0 3px $brand-primary;
    margin: 0 auto;

    @include wide {
      height: 103px;
      width: 103px;
      border: 3px solid white;
      margin: -9px 0 0 -5px;
    }

    img {
      max-width: 35px;

      @include wide {
        max-width: 100%;
      }
    }
  }

  .name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 19.5px;
    font-family: 'poppins', 'sans-serif';
  }

  hr {
    border-color: $brand-primary;
    border-width: 2px;
    margin-top: 12px;
    margin-bottom: 11px;
  }

  .author-col {
    @include wide {
      width: 350px;
      max-width: 339px;
    }

    @include hd {
      padding-left: 0;
    }

    p {
      line-height: 1.48;
    }
  }
}

.footer-info {
  font-weight: 400;

  .brand {
    margin-top: 34px;
    display: inline-block;

    img {
      left: 0;
      top: 14px;
    }
  }

  .footer-address {
    margin-top: 52px;

    @include hd {
      margin-right: -45px;
    }

    span {
      display: block;

      @include tablet {
        display: inline;
      }
    }

    .social-icon {
      margin-bottom: 5px;
      display: inline-block;
      font-size: 16px;

      @include tablet {
        margin-bottom: 0;
      }

      a {
        &::after {
          display: none;
        }
      }

      i {
        color: black;

        @include links-transition;

        &:hover {
          color: #7d7d7d;
        }
      }

      img {
        margin-top: -3px;
        width: 27px;
        filter: none;

        @include links-transition;

        &:hover {
          filter: opacity(0.5) drop-shadow(0 0 0 white);
        }
      }
    }

    a:hover {
      color: $brand-primary;
    }

    .spacer {
      font-weight: 400;
      margin: 0 19px;
      display: none;

      @include tablet {
        display: inline;
      }
    }

    a[href^="tel:"] {
      color: inherit;
      // margin: 22px 0;
      display: inline-block;

      :hover {
        color: inherit;
      }

      @include tablet {
        margin: 0;
      }
    }
  }

  .social-icon:not(:last-child) {
    margin-right: 9px;
  }

  a {
    color: $black;
  }

  .footer-nav {
    display: flex;

    .nav {
      align-items: center;
      justify-content: center;
      margin: 0;
      list-style: none;
      padding: 3px 0 7px;
      display: block;

      @include tablet {
        display: flex;
      }

      @include wide {
        padding: 9px 0 21px;
      }

      li {
        padding: 0 23.9px;

        @include hd {
          padding: 0 26.9px;
        }

        a {
          color: $black;
          font-weight: 400;
          line-height: 3;

          @include wide {
            line-height: 1.6;
          }
        }

        a:hover {
          color: $brand-primary;
        }

        a[target="_blank"]::after {
          display: none;
        }
      }

      .menu-item-has-children {
        a::after {
          display: none;
        }

        .sub-menu {
          display: none;
        }
      }
    }
  }
}

.copyright {
  background-color: #e8e7e7;
  padding: 8px 0;
  font-size: 12px;

  a {
    color: $black;

    &::after {
      display: none;
    }
  }
}
