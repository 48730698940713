.page-hero {
  .hero {
    @include laptop {
      height: 600px;
    }

    @include wide {
      height: 640px;
    }

    @include hd {
      height: 740px;
    }
  }

  .hero-content {
    background-color: #dedede;

    @include laptop {
      background-color: transparent;
    }

    .hero-content-inner {
      .hero-text {
        @include laptop {
          margin-top: 95px;
        }

        @include wide {
          margin-top: 100px;
        }
      }
    }
  }
}

.hero-container.footer-hero-container {
  margin: 0;

  .hero {
    height: 268px;

    @include mobile {
      height: 368px;
    }

    @include tablet {
      height: 368px;
    }

    @include laptop {
      height: 564px;
    }
  }

  .hero-content {
    height: fit-content;
    background-color: #dedede;

    @include laptop {
      height: 100%;
      background-color: transparent;
    }

    .hero-content-inner {
      @include laptop {
        width: 775px;
      }

      .hero-text {
        font-size: 43px;

        @include mobile {
          font-size: 58px;
        }

        @include phablet {
          font-size: 76px;
        }

        @include phablet {
          font-size: 76px;
        }

        @include laptop {
          font-size: 99.6px;
          margin-top: -14.5px;
        }

        @include desktop {
          font-size: 113.6px;
        }
      }
    }
  }
}

.hero-container {
  position: relative;
  z-index: 0;
  margin-top: -105px;
}

.hero {
  width: 100%;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;

  @include mobile {
    height: 468px;
  }

  @include tablet {
    font-size: 96px;
  }

  @include laptop {
    background-color: rgb(0 0 0 / 40%);
    background-blend-mode: overlay;
    height: 800px;
  }

  @include wide {
    height: 839px;
  }

  @include hd {
    height: 939px;
  }
}

.hero-content {
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  position: relative;

  @include laptop {
    position: absolute;
  }

  .hero-content-inner {
    width: 100%;
    text-align: center;

    @include laptop {
      width: 650px;
      text-align: left;
      padding: 0;
    }

    .hero-text {
      font-weight: 800;
      line-height: 0.835;
      margin-top: 0;
      margin-bottom: 35px;
      color: #434343;
      font-size: 51px;

      @include mobile {
        font-size: 76px;
      }

      @include tablet {
        font-size: 96px;
      }

      @include laptop {
        text-shadow: 2px 2px 2px #000;
        margin-top: -48.5px;
        margin-bottom: 41.5px;
        font-size: 113.6px;
        color: #eee;
      }

      @include hd {
        margin-left: 2.5px;
      }
    }

    .btn-primary {
      padding: 12px 32.5px 10px 28px;
      margin-bottom: 7px;

      @include mobile {
        margin-bottom: 12px;
      }

      @include laptop {
        margin-bottom: 0;
      }
    }

    .cta-2 {
      display: inline-block;
      background: #829400;
      padding: 12px 30.5px 10px 28px;

      @include tablet {
        margin-left: 25px;
      }
    }
  }
}

.strapline {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(33 33 33);

  @include laptop {
    position: absolute;
    background-color: rgb(33, 33, 33, 80%);
  }

  .strapline-text {
    color: white;
    text-align: center;
    margin: 0 auto;
    font-size: 16.69px;
    font-weight: 500;
    line-height: 1.6;
    padding: 32px 0;

    @include laptop {
      line-height: 2.1;
    }

    @include wide {
      width: 96%;
      padding: 41px 0;
    }

    @include hd {
      width: 1330px;
    }
  }
}
